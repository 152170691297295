<template>
  <div @contextmenu="this.setContextIdWidget">
    <span class="hidden">{{ darkDark }}</span>
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 25 + 'px',
        left: this.mouse_x + 'px',
      }"
      :id="'tooltip' + this.widgetId"
    >
      <div v-if="isTvdEWNSTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of tvdEWNSTooltipValues.split('</br>')">
          {{ msg }}
        </p>
        <!-- {{tvdEWNSTooltipValues | json}} -->
        <!-- <p :key="i" v-for="(msg, i) of tvdEWNSTooltipValues">
          {{ msg }}
        </p> -->
      </div>
    </div>
    <div
      class="shadow-lg"
      :style="{
        background:
          darkDark !== 'white'
            ? gridBackground || 'var(--navBar2Bg)'
            : gridBackground || 'var(--navBar2Bg)',
        transition: 'var(--transition)',
      }"
      :id="'trajectoryTrend' + this.widgetId"
    ></div>
  </div>
</template>

<script>
import {
  select,
  selectAll,
  scaleLinear,
  axisBottom,
  axisLeft,
  bisector,
  pointer,
  line,
} from "d3";
import apiService from "../../../api/services";
import helperServices from "../../../helper-services"

var svg = "";
export default {
  name: "TrajectoryLineGraph",
  components: {},
  props: {
    xAutoScale: {
      type: [Boolean],
      default: false,
    },
    yAutoScale: {
      type: [Boolean],
      default: false,
    },
    xRangeStart: {
      type: [Number, String, undefined],
      default: -1000,
    },
    xRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    yRangeStart: {
      type: [Number, String, undefined],
      default: -1000,
    },
    yRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    openConfig: Function,
    displayId: String,
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 200,
    },
    gridColor: {
      type: String,
      default: "",
    },

    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "var(--textColor)",
    },
    yAxisLabel: {
      type: String,
      default: "var(--textColor)",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    yAxisLabelColor: {
      type: String,
      default: "var(--textColor)",
    },
    backgroundColor: {
      type: String,
      default: "rgb(200, 200, 230)",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    planColor: {
      type: String,
      default: "#00d15a",
    },
    unit: {
      type: String,
      default: "",
    },
    unit_conversion_factor: {
      type: [String,Number],
      default: "",
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      isTvdEWNSTooltip: false,
      tvdEWNSTooltipValues: "",
      mouse_x: 0,
      mouse_y: 0,
      megaData: [],
      megaData1: [],
      properties: {},
      width: this.widgetWidth,
      height: this.widgetHeight,
      graphData: {
        rows: [],
        rowsPlan: [],
        columns: [],
        columnsPlan:[]
      },
      selectedTags: {
        x: "dispew",
        y: "dispns",
        z: "md",
        a: "tvd",
        b: "incl",
        c: "azi",
        d: "vertsect",
        e: "dogleg",
      },
      actualLegend: "visible",
      planLegend: "visible",
      dmea_units: null,
      replayTimeInterval:null
    };
  },
  computed: {
    darkDark() {
      if (this.xAxisLabel) {
        // this.$store.dispatch("rect/lineGraphYAxis", {
        //   displayId: this.displayId,
        //   widgetId: this.widgetId,
        //   value:  "white",
        // });
      } else {
        console.log("tvd section color change", this.xAxisLabel);
      }

      if (this.$store.state.rect.darkmode) {
        // this.$store.dispatch("rect/lineGraphYAxis", {
        //   displayId: this.displayId,
        //   widgetId: this.widgetId,
        //   value:  "white",
        // });
        // this.$store.dispatch("rect/lineGraphXAxis", {
        //   displayId: this.displayId,
        //   widgetId: this.widgetId,
        //   value: "white",
        // });
        // valueColor
      } else {
        // this.$store.dispatch("rect/lineGraphYAxis", {
        //   displayId: this.displayId,
        //   widgetId: this.widgetId,
        //   value: "black",
        // });
        // this.$store.dispatch("rect/lineGraphXAxis", {
        //   displayId: this.displayId,
        //   widgetId: this.widgetId,
        //   value: "black",
        // });
      }
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    async setting_unit(){
      try{
        const dtls = this.$store.state.disp.displays[this.displayId];
        let mapping = await helperServices.getIndexDetails(dtls.wellId);
        mapping = mapping.data.logs[dtls.logType];
        const tags = mapping.tags;
        const mnemonicIndex = tags.findIndex((t) => t == "dmea");
        const sourceMnemonic = mapping.source[mnemonicIndex];
        this.dmea_units = null;
        for(let type in mapping.mnemonics){
          if(mapping.mnemonics[type].hasOwnProperty(sourceMnemonic)){
            this.dmea_units = mapping.mnemonics[type][sourceMnemonic]
            break;
          }
        }
        if(!this.$store.state.rect.rects[this.idx]?.unit && this.dmea_units?.display_unit){
          this.$store.dispatch("rect/swabSurgeRealTimedata", {
            displayId: this.displayId,
            widgetId: this.widgetId,
            value: this.dmea_units?.display_unit,
            field: 'unit'
          });
        }
      }
      catch(err){
        console.error(err);
      }
    },
    formatNumber(number, from = null) {
      const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
      let suffixIndex = 0;

      while (Math.abs(number) >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
      }
      if(from == "yaxis" && Math.abs(number) >= 100 && Math.abs(number) <= 999 && suffixes.length-1 > suffixIndex){
        number /= 1000;
        suffixIndex++;
      }
      return number.toFixed(1) + suffixes[suffixIndex];
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async get3DGraphData(dtls) {
      try {
        const response = await apiService.WellServices.getPlanActualData({
          well_id: dtls.wellId,
          wellbore_name: dtls.wellboreId,
        });
        
        if(this.isReplay){
         this.graphData.rows=[];
         this.graphData.rowsPlan=response.data.rowsPlan;
         this.graphData.columns=response.data.columns;
         this.graphData.columnsPlan=response.data.columnsPlan;
         let index=0;
        if(this.replayTimeInterval){
          clearInterval(this.replayTimeInterval);
          this.replayTimeInterval=null;
        }
        this.replayTimeInterval = setInterval(async () => {
          if (index < response.data.rows.length) {
            this.graphData.rows.push(response.data.rows[index]);
            index++;
            await this.set3DGraphData();
            this.buildSVG()
          } else {
            clearInterval(this.replayTimeInterval);  
            this.replayTimeInterval=null;
          }
        }, 1000);
        }else{
          this.graphData = response.data;
        }
      } catch (error) {}

      await this.set3DGraphData();
    },
    set3DGraphData() {
      this.megaData = this.graphData.rows.map((ele) => {
        return {
          x: ele[this.selectedTags.x],
          y: ele[this.selectedTags.y],
          z: ele[this.selectedTags.z],
          a: ele[this.selectedTags.a],
          b: ele[this.selectedTags.b],
          c: ele[this.selectedTags.c] ? ele[this.selectedTags.c] : 0,
          d: ele[this.selectedTags.d] ? ele[this.selectedTags.d] : 0,
          e: ele['dls'] ? ele['dls'] : 0,
        };
      });

      //
      this.megaData1 = this.graphData.rowsPlan.map((ele) => {
        return {
          x: ele[this.selectedTags.x] ? ele[this.selectedTags.x] : 0,
          y: ele[this.selectedTags.y] ? ele[this.selectedTags.y] : 0,
          z: ele[this.selectedTags.z] ? ele[this.selectedTags.z] : 0,
          a: ele[this.selectedTags.a] ? ele[this.selectedTags.a] : 0,
          b: ele[this.selectedTags.b] ? ele[this.selectedTags.b] : 0,
          c: ele[this.selectedTags.c] ? ele[this.selectedTags.c] : 0,
          d: ele[this.selectedTags.d] ? ele[this.selectedTags.d] : 0,
          e: ele[this.selectedTags.e] ? ele[this.selectedTags.e] : 0,
        };
      });
      //
      this.megaData = this.megaData.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      this.megaData1 = this.megaData1.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      //
      //
      const allTags = [
        ...this.graphData.columns,
        ...this.graphData.columnsPlan,
      ];
      const tempTags = allTags.map((ele) => ele.field);
      const tags = [...new Set(tempTags)];
      // const tags = this.graphData.columns.map((ele) => ele.field);
      if(!this.isReplay){
        this.$store.dispatch("disp/setTrejectoryTags", {
          display: this.displayId,
          tags,
        });
      }
    },
    async clearSvgHandler() {
      return new Promise((res, rej) => {
        //
        try {
          let gauge = select("#trajectoryTrend" + this.widgetId);
          gauge.selectAll("*").remove();
          svg = "";

          res();
        } catch (error) {}
      });
    },
    async buildSVG() {
      if (this.xRangeStart == this.xRangeEnd) return;
      if (this.yRangeStart == this.yRangeEnd) return;
      var margin = { top: 10, right: 40, bottom: 50, left: 70 },
        width = this.width - margin.left - margin.right,
        height = this.height - margin.top - margin.bottom;

      let xlabelFontSize = this.xAxisLabelSize || 12;
      let ylabelFontSize = this.yAxisLabelSize || 12;
      let alabelFontSize = 12;
      let blabelFontSize = 12;

      let tooltipFontSize = 10;
      var maxX = 0;
      var maxY = 0;
      var minX = 0;
      var minY = 0;

      //create base svg
      svg = select("#trajectoryTrend" + this.widgetId);

      if (svg) {
        svg.selectAll("*").remove();
        //
        svg = "";
      } else {
        //
        let gauge = document.createElement("div");
        gauge.setAttribute("id", "trajectoryTrend" + this.widgetId);
      }
      svg = select("#trajectoryTrend" + this.widgetId)
        .append("svg")
        .attr("width", width + margin.left + margin.right - 30)
        .attr("height", height + margin.top + margin.bottom)
        .style("overflow", "visible")
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      //structure plane trajectory Plan Plot
      var planData = [];
      try {
        planData = this.megaData1.map(function (d) {
          let obj = {
            y: parseFloat(d["y"]),
            x: parseFloat(d["x"]),
            z: parseFloat(d["z"]),
            a: parseFloat(d["a"]),
            b: parseFloat(d["b"]),
            c: parseFloat(d["c"]),
            d: parseFloat(d["d"]),
            e: parseFloat(d["e"]),
            name: "PT",
          };
          maxX = maxX < obj.x ? obj.x : maxX;
          minX = minX < obj.x ? minX : obj.x;
          maxY = maxY < obj.y ? obj.y : maxY;
          minY = minY < obj.y ? minY : obj.y;
          return obj;
        });
      } catch (error) {
        console.error(error);
      }

      //structure current trajectory Actuall Plot
      var actualData = this.megaData.map(function (d) {
        let obj = {
          y: parseFloat(d["y"]),
          x: parseFloat(d["x"]),
          z: parseFloat(d["z"]),
          a: parseFloat(d["a"]),
          b: parseFloat(d["b"]),
          c: parseFloat(d["c"]),
          d: parseFloat(d["d"]),
          e: parseFloat(d["e"]),
          name: "CT",
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
      });

      //

      //create axis
      if (this.xAutoScale) {
        // console.log("in tvd section x auto scale:::",this.xAutoScale)
        // this.block is for manual entry of x ranges
        minX = this.xRangeStart;
        maxX = this.xRangeEnd;
        console.log(
          "xTicksArray ****************in range start",
          this.xRangeStart
        );
      }
      if (this.yAutoScale) {
        minY = this.yRangeStart;
        maxY = this.yRangeEnd;
      }
      // if (maxX && this.xAutoScale && this.yAutoScale) {
      //   if (parseFloat(maxX) <= parseFloat(minX)) {
      //     this.$toast.error(
      //       "X-axis End Range must be greater than Start Range",
      //       { duration: 2000, position: "top" }
      //     );
      //     return;
      //   } else if (maxY <= minY) {
      //     this.$toast.error(
      //       "Y-axis End Range must be greater than Start Range",
      //       { duration: 2000, position: "top" }
      //     );
      //     return;
      //   }
      // }
      if (this.unit_conversion_factor && this.unit_conversion_factor != "") {
        const newMinY = minY * this.unit_conversion_factor;
        const newMaxY = maxY * this.unit_conversion_factor;
        const newMinX = minX * this.unit_conversion_factor;
        const newMaxX = maxX * this.unit_conversion_factor;
        minX = newMinX;
        maxX = newMaxX;
        minY = newMinY;
        maxY = newMaxY;
      }
      var x = scaleLinear()
        .domain([minX, maxX])
        .range([0, width - 10]);
      var y = scaleLinear().domain([minY, maxY]).range([height, 0]);

      //create planned line chart
      let filteredPlanData = planData.filter((d) => {
        return (
          !isNaN(d.x) &&
          d.x >= minX &&
          d.x <= maxX &&
          maxY >= d.y &&
          d.y >= minY
        );
      });
      if(this.unit_conversion_factor && this.unit_conversion_factor!=""){
        const unitMappedData = filteredPlanData.map(e=>{
          return{...e,x:e.x * this.unit_conversion_factor,y:e.y * this.unit_conversion_factor }
        });
        filteredPlanData =  unitMappedData;
        let re_filteredPlanData = filteredPlanData.filter((d) => {
          return (
            !isNaN(d.x) &&
            d.x >= minX &&
            d.x <= maxX &&
            maxY >= d.y &&
            d.y >= minY
          );
        });
        filteredPlanData = re_filteredPlanData;
      }
      
      svg
        .append("path")
        .datum(filteredPlanData)
        .attr("fill", "none")
        .attr("stroke", this.planColor)
        .attr("stroke-width", 3)
        .attr("class", "plan_line")
        .attr("visibility", this.planLegend)
        // .style("stroke-dasharray", "3, 3")
        .attr(
          "d",
          line()
            .x(function (d) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })
        );
      // .on("mouseover", function (e) {
      //   let data = select(this).data()[0];
      //   var bisect = bisector(function (d) {
      //     return d.x;
      //   }).left;
      //   var bisectY = bisector(function (d) {
      //     return d.y;
      //   }).left;
      //   let x0 = x.invert(pointer(e)[0]);
      //   let y0 = y.invert(pointer(e)[1]);
      //   let i = bisect(data, x0, 1) - 1;
      //   let j = bisectY(data, y0, 1) - 1;
      //   console.log("mousemove : 1 ", select(this).data());
      //   let d0 = data[j] || data[i];
      //   if (data[j] || data[i]) {
      //     let diff1 = data[i] ? Math.abs(y0 - data[i].y) : 0;
      //     let diff2 = data[j] ? Math.abs(y0 - data[j].y) : 0;
      //     let diffX1 = data[i] ? Math.abs(x0 - data[i].x) : 0;
      //     let diffX2 = data[j] ? Math.abs(x0 - data[j].x) : 0;
      //     if (diff1 > diff2 && diffX1 > diffX2) {
      //       d0 = data[j];
      //     } else if (diff1 > diff2 && diffX1 < diffX2) {
      //       d0 = data[j] || data[i];
      //     } else {
      //       d0 = data[i] || data[j];
      //     }
      //     realTimeTooltip(e, [d0], true, "Plan");
      //   }
      // })
      // .on("mousemove", function (e) {
      //   let data = select(this).data()[0];
      //   var bisect = bisector(function (d) {
      //     return d.x;
      //   }).left;
      //   var bisectY = bisector(function (d) {
      //     return d.y;
      //   }).left;
      //   let x0 = x.invert(pointer(e)[0]);
      //   let y0 = y.invert(pointer(e)[1]);
      //   let i = bisect(data, x0, 1) - 1;
      //   let j = bisectY(data, y0, 1) - 1;
      //   let d0 = data[j] || data[i];
      //   console.log("mousemove : 2 ", i, j, data[i], data[j]);
      //   if (data[j] || data[i]) {
      //     let diff1 = data[i] ? Math.abs(y0 - data[i].y) : 0;
      //     let diff2 = data[j] ? Math.abs(y0 - data[j].y) : 0;
      //     let diffX1 = data[i] ? Math.abs(x0 - data[i].x) : 0;
      //     let diffX2 = data[j] ? Math.abs(x0 - data[j].x) : 0;
      //     if (diff1 > diff2 && diffX1 > diffX2) {
      //       d0 = data[j];
      //     } else if (diff1 > diff2 && diffX1 < diffX2) {
      //       d0 = data[j] || data[i];
      //     } else {
      //       d0 = data[i] || data[j];
      //     }
      //     realTimeTooltip(e, [d0], true, "Plan");
      //   }
      // })
      // .on("mouseout", function (e) {
      //   hideRealTimeTooltip(e, "", false);
      // });

      svg
        .append("g")
        .selectAll("dot")
        .data(filteredPlanData)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return x(d.x);
        })
        .attr("cy", function (d) {
          return y(d.y);
        })
        .attr("r", 4)
        .style("fill", "green")
        .attr("visibility", this.planLegend)
        .attr("class", "plan_dot_tvd")
        .attr("opacity", 0)
        .on("mouseover", function (e) {
          let data = select(this);
          data.attr("opacity", 1);
          realTimeTooltip(e, data.data(), true, "Plan");
        })
        .on("mousemove", function (e) {
          let data = select(this);
          data.attr("opacity", 1);
          realTimeTooltip(e, data.data(), true, "Plan");
        })
        .on("mouseout", function (e) {
          let data = select(this);
          data.attr("opacity", 0);
          hideRealTimeTooltip(e, "", false);
        });

      let filteredData = actualData.filter((d) => {
        return (
          !isNaN(d.x) &&
          d.x >= minX &&
          d.x <= maxX &&
          maxY >= d.y &&
          d.y >= minY
        );
        // return false
      });
      if(this.unit_conversion_factor && this.unit_conversion_factor!=""){
        const unitMappedData = filteredData.map(e=>{
          return{...e,x:e.x * this.unit_conversion_factor,y:e.y * this.unit_conversion_factor }
        });
        filteredData =  unitMappedData;
      }

      svg
        .append("path")
        .datum(filteredData)
        .attr("fill", "none")
        .attr("stroke", this.actualColor)
        .attr("stroke-width", 3)
        .attr("class", "actual_line")
        .attr("visibility", this.actualLegend)
        .attr(
          "d",
          line()
            .x(function (d) {
              if (maxX >= d.x && d.x >= minX) return x(d.x);
            })
            .y(function (d) {
              if (maxY >= d.y && d.y >= minY) return y(d.y);
            })
        );
      // .on("mouseover", function (e) {
      //   let data = select(this).data()[0];
      //   var bisect = bisector(function (d) {
      //     return d.x;
      //   }).left;
      //   var bisectY = bisector(function (d) {
      //     return d.y;
      //   }).left;
      //   let x0 = x.invert(pointer(e)[0]);
      //   let y0 = y.invert(pointer(e)[1]);
      //   let i = bisect(data, x0, 1) - 1;
      //   let j = bisectY(data, y0, 1) - 1;
      //   console.log("mousemove : 3 ", select(this).data());
      //   let d0 = data[i] || data[j];

      //   if (data[j] || data[i]) {
      //     let diff1 = data[i] ? Math.abs(y0 - data[i].y) : 0;
      //     let diff2 = data[j] ? Math.abs(y0 - data[j].y) : 0;
      //     let diffX1 = data[i] ? Math.abs(x0 - data[i].x) : 0;
      //     let diffX2 = data[j] ? Math.abs(x0 - data[j].x) : 0;
      //     if (diff1 > diff2 && diffX1 > diffX2) {
      //       d0 = data[j];
      //     } else if (diff1 > diff2 && diffX1 < diffX2) {
      //       d0 = data[j] || data[i];
      //     } else {
      //       d0 = data[i] || data[j];
      //     }
      //     realTimeTooltip(e, [d0], true, "Actual");
      //   }
      // })
      // .on("mousemove", function (e) {
      //   let data = select(this).data()[0];
      //   console.log("mousemove : 4 ", select(this).data());
      //   var bisect = bisector(function (d) {
      //     return d.x;
      //   }).left;
      //   var bisectY = bisector(function (d) {
      //     return d.y;
      //   }).left;
      //   let x0 = x.invert(pointer(e)[0]);
      //   let y0 = y.invert(pointer(e)[1]);
      //   let i = bisect(data, x0, 1) - 1;
      //   let j = bisectY(data, y0, 1) - 1;
      //   let d0 = data[i] || data[j];
      //   if (data[j] || data[i]) {
      //     let diff1 = data[i] ? Math.abs(y0 - data[i].y) : 0;
      //     let diff2 = data[j] ? Math.abs(y0 - data[j].y) : 0;
      //     let diffX1 = data[i] ? Math.abs(x0 - data[i].x) : 0;
      //     let diffX2 = data[j] ? Math.abs(x0 - data[j].x) : 0;
      //     if (diff1 > diff2 && diffX1 > diffX2) {
      //       d0 = data[j];
      //     } else if (diff1 > diff2 && diffX1 < diffX2) {
      //       d0 = data[j] || data[i];
      //     } else {
      //       d0 = data[i] || data[j];
      //     }
      //     realTimeTooltip(e, [d0], true, "Actual");
      //   }
      // })
      // .on("mouseout", function (e) {
      //   hideRealTimeTooltip(e, "", false);
      // });

      svg
        .append("g")
        .selectAll("dot")
        .data(filteredData)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return x(d.x);
        })
        .attr("cy", function (d) {
          return y(d.y);
        })
        .attr("r", 4)
        .style("fill", "red")
        .attr("opacity", 0)
        .attr("class", "actual_dot_tvd")
        .attr("visibility", this.actualLegend)
        .on("mouseover", function (e) {
          let data = select(this);
          data.attr("opacity", 1);
          realTimeTooltip(e, data.data(), true, "Actual");
        })
        .on("mousemove", function (e) {
          let data = select(this);
          data.attr("opacity", 1);
          realTimeTooltip(e, data.data(), true, "Actual");
        })
        .on("mouseout", function (e) {
          let data = select(this);
          data.attr("opacity", 0);
          hideRealTimeTooltip(e, "", false);
        });

      var xAxis, yAxis;
      let xTicksArray = [];
      let yTicksArray = [];
      if (this.xAutoScale) {
        let tickInterval = parseFloat(maxX - minX) / 10;
        for (
          let tick = parseFloat(minX);
          tick <= maxX;
          tick = tick + tickInterval
        ) {
          if (xTicksArray.length <= 9) xTicksArray.push(tick);
        }
        xTicksArray.push(maxX);
        console.log(
          "xTicksArray **************** ",
          xTicksArray,
          tickInterval,
          minX,
          maxX
        );
        let xTicks = axisBottom().scale(x).tickValues(xTicksArray);
        xAxis = xTicks;
      } else {
        x.nice();
        xAxis = axisBottom(x).ticks();
      }
      if (this.yAutoScale) {
        let tickInterval = parseFloat(maxY - minY) / 10;
        for (
          let tick = parseFloat(minY);
          tick <= maxY;
          tick = tick + parseFloat(tickInterval)
        ) {
          if (yTicksArray.length <= 9) yTicksArray.push(tick);
        }
        yTicksArray.push(maxY);
        let yTicks = axisLeft().scale(y).tickValues(yTicksArray);
        yAxis = yTicks;
      } else {
        y.nice();
        yAxis = axisLeft(y).ticks();
      }
      //  const xAxis = axisBottom(x).ticks();
      //  const yAxis = axisLeft(y).ticks();
      //create grid axis
      let xAxisGrid, yAxisGrid;
      xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      yAxisGrid = axisLeft(y)
        .tickSize(-width + 10)
        .tickFormat("")
        .ticks();
      if (this.xAutoScale) {
        xAxisGrid = axisBottom(x)
          .tickSize(-height)
          .tickFormat("")
          .tickValues(xTicksArray);
      }
      if (this.yAutoScale) {
        yAxisGrid = axisLeft(y)
          .tickSize(-width + 10)
          .tickFormat("")
          .tickValues(yTicksArray);
      }
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width * 0.58)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", xlabelFontSize)
        .text(this.selectedTags.x.toUpperCase() + ` (${this.unit && this.unit!=""?this.unit:'ft'})`);

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        // .attr("x", height/2)
        .attr("dy", -55)
        .attr("dx", -(height - margin.bottom) / 2)
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", ylabelFontSize)
        .text(this.selectedTags.y.toUpperCase() +` (${this.unit && this.unit!=""?this.unit:'ft'})`);
      // Create grids.
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
        .style("z-index", -1)
        .call(xAxisGrid);

      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
        .style("z-index", -1)
        .call(yAxisGrid);
      svg.selectAll('g.axis-grid').selectAll('line').style('stroke', this.gridColor)
      svg.selectAll('g.axis-grid').selectAll('text').style('fill', this.gridColor)
      // Create axes.
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", 12)
        .attr("fill", this.xAxisLabel)
        .call(xAxis);

      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .attr("fill", this.yAxisLabel)
        .attr("transform", "rotate(-20)")
        .text((d)=>{
          return this.formatNumber(d);
        });
      svg.append("g").attr("class", "yAxis").style("font-size", 12).call(yAxis);
      svg
        .selectAll(".yAxis")
        .selectAll("text")
        .attr("fill", this.yAxisLabel)
        .attr("transform", "rotate(-20)")
        .text((d)=>{
          return this.formatNumber(d, "yaxis");
        });
      // Set the gradient
      svg
        .append("linearGradient")
        .attr("id", "line-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", y(0))
        .attr("x2", 0)
        .attr("y2", y(maxY))
        .selectAll("stop")
        .data([
          { offset: "0%", color: "blue" },
          { offset: "10%", color: "green" },
          { offset: "40%", color: "brown" },
          { offset: "70%", color: this.planColor },
          { offset: "100%", color: this.actualColor },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
          return d.offset;
        })
        .attr("stop-color", function (d) {
          return d.color;
        });
      //create actual line chart
      let realTimeTooltip = (event, data, isShow, dataFor) => {
        //
        this.mouse_x = event.clientX + 20;
        this.mouse_y = event.clientY;
        let text = "";

        // text = `${dataFor}</br>${this.selectedTags.x.toUpperCase()} : ${data[0][
        //   "x"
        // ].toFixed(2)} </br>${this.selectedTags.y.toUpperCase()} : ${data[0][
        //   "y"
        // ].toFixed(2)} </br>${this.selectedTags.z.toUpperCase()} : ${data[0][
        //   "z"
        // ].toFixed(2)}`;


        text = `${dataFor}</br>${this.selectedTags.z.toUpperCase()} : ${data[0]["z"].toFixed(2)}`
        if (data[0]["b"]) text += `</br>INCL : ${data[0]["b"].toFixed(2)}`
        if (data[0]["c"]) text += `</br>AZI : ${data[0]["c"].toFixed(2)}`
        if (data[0]["a"]) text += `</br>TVD : ${data[0]["a"].toFixed(2)}`
        if (data[0]["d"]) text += `</br>VERTICAL SECTION : ${data[0]["d"].toFixed(2)}`
        text += `</br>${this.selectedTags.y.toUpperCase()} : ${data[0]["y"].toFixed(2)}`
        text += `</br>${this.selectedTags.x.toUpperCase()} : ${data[0]["x"].toFixed(2)}`
        if (data[0]["e"]) text += `</br>DLS : ${data[0]["e"].toFixed(2)}`

        // c-azi, d- vertical section, e- dls
        
        // if (data[0]["a"]) text += `</br>TVD : ${data[0]["a"].toFixed(2)}`;

        // if (data[0]["b"]) text += `</br>INCL : ${data[0]["b"].toFixed(2)}`;

        this.tvdEWNSTooltipValues = text;
        //
        this.isTvdEWNSTooltip = isShow;
        //
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        // if (this.tooltipTimeOut) {
        //   clearTimeout(this.tooltipTimeOut);
        // }
        // this.tooltipTimeOut = setTimeout(() => {
        this.mouse_x = 0;
        this.mouse_y = 0;
        this.tvdEWNSTooltipValues = "";
        this.isTvdEWNSTooltip = isShow;
        // }, 0);
      };

      let g_for_tvd_actual_legend = svg
        .append("g")
        .attr("class", "actualTextTvd")
        .style("opacity", this.actualLegend === "visible" ? 1 : 0.5);
      let g_for__tvd_plan_legend = svg
        .append("g")
        .attr("class", "planTextTvd")
        .style("opacity", this.planLegend === "visible" ? 1 : 0.5);
      g_for_tvd_actual_legend
        .append("rect")
        .attr("class", "x label actualDotTvd")
        .attr("text-anchor", "end")
        .attr("ry", 20)
        .attr("x", width - 105)
        .attr("y", height + 32)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", this.actualColor)
        .style("cursor", "pointer");

      g_for_tvd_actual_legend
        .append("text")
        .attr("class", "x label actualTextTvd")
        .attr("text-anchor", "end")
        .attr("x", width - 50)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", alabelFontSize)
        .style("cursor", "pointer")
        .text("Actual");

      g_for__tvd_plan_legend
        .append("rect")
        .attr("class", "x label planDotTvd")
        .attr("text-anchor", "end")
        .attr("ry", 20)
        .attr("x", width - 40)
        .attr("y", height + 32)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", this.planColor)
        .style("cursor", "pointer");

      g_for__tvd_plan_legend
        .append("text")
        .attr("class", "x label planTextTvd")
        .attr("text-anchor", "end")
        .attr("x", width + 2)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", blabelFontSize)
        .style("cursor", "pointer")
        .text("Plan");

      g_for_tvd_actual_legend.on("click", (d) => {
        if (select(".actual_line").attr("visibility") == "visible") {
          this.actualLegend = "hidden";
          select(".actual_line").attr("visibility", this.actualLegend);
          selectAll(".actual_dot_tvd").filter(function () {
            return select(this).attr("visibility", "hidden");
          });
          select(".actualTextTvd").style("opacity", 0.5);
          console.log("legend display:::::", this.actualLegend);
        } else {
          this.actualLegend = "visible";
          select(".actual_line").attr("visibility", this.actualLegend);
          selectAll(".actual_dot_tvd").filter(function () {
            return select(this).attr("visibility", "visible");
          });
          select(".actualTextTvd").style("opacity", 1);
        }
      });

      g_for__tvd_plan_legend.on("click", (d) => {
        if (select(".plan_line").attr("visibility") == "visible") {
          this.planLegend = "hidden";
          select(".plan_line").attr("visibility", this.planLegend);
          selectAll(".plan_dot_tvd").filter(function () {
            return select(this).attr("visibility", "hidden");
          });
          select(".planTextTvd").style("opacity", 0.5);
        } else {
          this.planLegend = "visible";
          select(".plan_line").attr("visibility", this.planLegend);
          selectAll(".plan_dot_tvd").filter(function () {
            return select(this).attr("visibility", "visible");
          });
          select(".planTextTvd").style("opacity", 1);
        }
      });
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  watch: {
    xAutoScale: function (newVal, oldVal) {
      this.buildSVG();
    },
    xRangeStart: function (newVal, oldVal) {
      if (this.xAutoScale) {
        if (isNaN(Number(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (Number(this.xRangeEnd) < Number(newVal)) {
            this.$toast.error(
              "X-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            console.log("Build xRangeStart");
            this.buildSVG();
          }
        }
      }
    },
    xRangeEnd: function (newVal, oldVal) {
      if (this.xAutoScale) {
        if (isNaN(Number(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (Number(this.xRangeStart) >= Number(newVal)) {
            this.$toast.error(
              "X-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            console.log("Build xRangeStart");
            this.buildSVG();
          }
        }
      }
    },
    yAutoScale: async function (newVal, oldVal) {
      this.buildSVG();
    },

    yRangeStart: async function (newVal, oldVal) {
      if (this.yAutoScale) {
        if (isNaN(Number(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (
            Number(this.yRangeEnd) < Number(newVal) ||
            Number(this.yRangeEnd) === Number(newVal)
          ) {
            this.$toast.error(
              "Y-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            this.buildSVG();
          }
        }
      }
    },
    yRangeEnd: async function (newVal, oldVal) {
      if (this.yAutoScale) {
        if (isNaN(Number(newVal))) {
          this.$toast.error("Not a valid number", {
            duration: 1000,
            position: "top",
          });
        } else {
          if (Number(this.yRangeStart) >= Number(newVal)) {
            this.$toast.error(
              "Y-axis End Range must be greater than Start Range",
              {
                duration: 1000,
                position: "top",
              }
            );
          } else {
            this.buildSVG();
          }
        }
      }
    },
    isSwitched: async function (newVal, oldVal) {
      try {
        let prevTags = { ...this.selectedTags };
        this.selectedTags.x = prevTags.y;
        this.selectedTags.y = prevTags.x;
        this.set3DGraphData();
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    actualColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    planColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    yAxisLabelColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    selectedTags: {
      handler(val) {
        this.set3DGraphData();
        this.buildSVG();
      },
      deep: true,
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.width = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.height = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    unit_conversion_factor(val){
      if(val && val!=""){
        this.buildSVG();
      }
    }
  },
  beforeUnmount() {
    try {
      clearInterval(this.loadTvdInterval);
      if(this.isReplay && this.replayTimeInterval){
        clearInterval(this.replayTimeInterval);  
      }
    } catch (error) {}
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.trajectoryLogId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      await this.setting_unit();
      await this.get3DGraphData(dtls);
      // this.buildSVG();
    }

    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type == "rect/LINE_GRAPH_MNEMONIC_CHANGE") {
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ) {
              this.selectedTags = {
                x: this.$store.state.rect.rects[i].mnemonicTag1 || "dispew",
                y: this.$store.state.rect.rects[i].mnemonicTag2 || "tvd",
                z: "md",
                a: "tvd",
                b: "incl",
              };
            }
          }
          // this.buildSVG();
        }
      }
      if (mutation.type == "disp/setDisplay") {
        if (mutation.payload.display == this.displayId) {
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.wellboreState = mutation.payload.wellboreState;
          this.logType = mutation.payload.logType;
          this.table = mutation.payload.table;
          await this.setting_unit();
          await this.get3DGraphData(mutation.payload);
          this.buildSVG();
          if(!this.isReplay){
            try {
              clearInterval(this.loadTvdInterval);
              this.loadTvdInterval = setInterval(async () => {
                await this.get3DGraphData(mutation.payload);
                this.buildSVG();
              }, 60000);
            } catch (error) {}
          }
        }
      }
    });

    this.buildSVG();
    if(!this.isReplay){
      try {
        clearInterval(this.loadTvdInterval);
        this.loadTvdInterval = setInterval(async () => {
          await this.get3DGraphData(dtls);
          this.buildSVG();
        }, 60000);
      } catch (error) {}
    }
  },
};
</script>

<style scoped>
.overlay {
  fill: none;
  pointer-events: all;
}

.focus circle {
  fill: rgb(0, 0, 0);
}

.focus text {
  font-size: 14px;
}

.tooltip {
  fill: white;
  stroke: rgb(255, 249, 249);
}

.tooltip-date,
.tooltip-likes {
  font-weight: bold;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}
</style>
